// Dans ChatPage.js
import React, { useState, useEffect } from "react";
import { Outlet, useParams } from "react-router-dom";
import { styled } from "@mui/system";
import { useNavigate } from "react-router-dom";
import {
  Box,
  List,
  ListItem,
  ListItemText,
  Typography,
  IconButton,
  TextField,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import Navbar from "../component/Navbar";
import axios from "axios";
import ChatIcon from "@mui/icons-material/Chat";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import MenuIcon from "@mui/icons-material/Menu";
import { useWebSocket } from "../WebSocketContext";
import { useSelector } from "react-redux";
import { CHAT_url } from "../GlobalVariables";

const MainContainer = styled(Box)(({ theme }) => ({
  backgroundColor: "#171717",
  height: "100vh", // Assurez-vous que cela couvre toute la page
  display: "flex",
  flexDirection: "column",
  overflow: "hidden", // Empêche les débordements
}));

const IconButtonStyled = styled(IconButton)(({ theme }) => ({
  color: "white",
  position: "relative",
  top: theme.spacing(1),
  left: theme.spacing(0.5),
  zIndex: 1,
  display: "none",
  [theme.breakpoints.down("md")]: {
    display: "flex",
  },
}));

const ContentContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
  fontFamily: "Poppins",
  overflow: "hidden",
  height:"100%",
  width: "100%",
  // minHeight: "100vh",
}));

const SidebarContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isOpen",
})(({ theme, isOpen }) => ({
  display: "flex",
  width: isOpen ? "100%" : "15%",
  minWidth: "250px",
  backgroundColor: "#0D0D0D",
  flexDirection: "column",
  alignItems: "center",
  [theme.breakpoints.up("lg")]: {
    width: isOpen ? "20%" : "15%",
  },
}));

const NewChatButton = styled(Box)(({ theme }) => ({
  textAlign: "center",
  marginTop: "15px",
  padding: theme.spacing(3),
  cursor: "pointer",
  color: "white",
  "&:hover": {
    backgroundColor: "rgba(255, 255, 255, 0.08)",
    borderRadius: "4px",
  },
}));

const StyledList = styled(List)(({ theme }) => ({
  width: "100%",
  maxHeight: "80vh",
  overflowY: "auto",
  borderRight: `1px solid ${theme.palette.divider}`,
  color: "white",
  "&::-webkit-scrollbar": {
    width: "5px",
  },
  "&::-webkit-scrollbar-track": {
    borderRadius: "10px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "darkgrey",
    borderRadius: "10px",
    "&:hover": {
      backgroundColor: "grey",
    },
  },
}));

const StyledListItem = styled(ListItem, {
  shouldForwardProp: (prop) => prop !== "isActive" && prop !== "isEditing",
})(({ theme, isActive, isEditing }) => ({
  marginTop: "5px",
  borderRadius: isActive ? "10px" : "0px",
  backgroundColor: isActive ? "rgba(255, 255, 255, 0.08)" : "transparent",
  "&:hover": {
    backgroundColor: "rgba(255, 255, 255, 0.2)",
    borderRadius: "10px",
  },
}));

const ActionIconButton = styled(IconButton)(({ theme, deleteBtn }) => ({
  color: "white",
  "&:hover": {
    color: deleteBtn ? "red" : "green",
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-input": {
    color: "white", // Ensures text is white
  },
}));

const ChatListItemText = styled(ListItemText)(({ theme }) => ({
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
}));

const ChatPage = () => {
  const base_url = `${CHAT_url}/chat/title`;
  const socket = useWebSocket();
  const theme = useTheme();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const { id: activeChatId } = useParams();
  const { id } = useParams();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [chats, setChats] = useState([]);
  const [currentChat, setCurrentChat] = useState(null);
  const [editingChatId, setEditingChatId] = useState(null);
  const [editedTitle, setEditedTitle] = useState("");
  const [id_user, setid_user] = useState(user.user._id);
  const [isSidebarOpen, setIsSidebarOpen] = useState(!isMobile);

  const fetchChats = async () => {
    try {
      const response = await axios.get(base_url + "?id_user=" + id_user);
      if (response != undefined && response != null) {
        if (response.status === 200) {
          const data = response.data;
          const titles = data.titles;

          const newChats = Object.keys(titles).map((key) => {
            return {
              index: key,
              title: titles[key][1],
              id: titles[key][0],
            };
          });
          setChats(newChats);
          if (newChats.length > 0) {
            navigate(`/Chatbot/${newChats[0].id}`);
          } else {
            navigate("/Chatbot/0");
          }
        } else {
          console.log(response.status);
        }
      }
    } catch (error) {
      console.log("Erreur lors de la récupération des chats");
      navigate("/Chatbot/0");
    }
  };

  useEffect(() => {
    fetchChats();
  }, []);

  useEffect(() => {
    if (user.user) {
      setid_user(user.user._id);
    }
  }, [user.user]);

  const startEditing = (chatId, currentTitle) => {
    setEditingChatId(chatId);
    setEditedTitle(currentTitle);
  };

  const cancelEditing = () => {
    setEditingChatId(null);
    setEditedTitle("");
  };

  const handleChatClick = (chatId) => {
    console.log(`Préparation à la navigation vers le chat ${chatId}`);
    if (socket.current) {
      console.log("On a bien un socket connecté donc on stop");
      socket.current.emit("listener", { message: "stop" });
    }
    navigate(`/Chatbot/${chatId}`);
  };

  const saveTitle = async (chatId) => {
    console.log(
      `Sauvegarder le titre: ${editedTitle} pour le chatId: ${chatId}`
    );
    setEditingChatId(null);
    setEditedTitle("");
    try {
      const response = await axios.put(
        base_url + "?id_user=" + id_user + "&id_conv=" + chatId,
        {
          title: editedTitle,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 204) {
        console.log("Mise à jour réussie");
        fetchChats();
      }
    } catch (error) {
      console.error(
        "Erreur lors de la mise à jour du titre du chat:",
        error.response ? error.response.data : error.message
      );
    }
  };

  const handleEditClick = (id) => {
    console.log(`Modifier ${id}`);
  };

  const handleDeleteClick = async (id) => {
    console.log(`Supprimer ${id}`);
    try {
      const response = await axios.delete(
        CHAT_url+"/chat" + "?id_user=" + id_user,
        {
          data: {
            ids_conv: [id],
          },
        }
      );

      if (response.status === 200) {
        console.log("Suppression réussie");
        fetchChats();
        navigate(0);
      }
    } catch (error) {
      console.error(
        "Erreur lors de la suppression du(des) chat(s):",
        error.response ? error.response.data : error.message
      );
    }
  };

  return (
    <MainContainer>
      {/* <Navbar isLoggedIn={false} color={"#0D0D0D"} /> */}
      <IconButtonStyled onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
        <MenuIcon />
      </IconButtonStyled>
      <ContentContainer>
        <SidebarContainer isOpen={isSidebarOpen}>
          <NewChatButton onClick={() => handleChatClick("0")}>
            <ChatIcon sx={{ fontSize: 40 }} />
            <Typography variant="h6">Nouveau chat</Typography>
          </NewChatButton>
          <StyledList>
            {chats.map((chat) => (
              <StyledListItem
                key={chat.id}
                isActive={chat.id === activeChatId}
                isEditing={editingChatId === chat.id}
                secondaryAction={
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <ActionIconButton
                      onClick={() => startEditing(chat.id, chat.title)}
                    >
                      <EditIcon />
                    </ActionIconButton>
                    <ActionIconButton
                      onClick={() => handleDeleteClick(chat.id)}
                      deleteBtn
                    >
                      <DeleteIcon />
                    </ActionIconButton>
                  </Box>
                }
              >
                {editingChatId === chat.id ? (
                  <StyledTextField
                    value={editedTitle}
                    onChange={(e) => setEditedTitle(e.target.value)}
                    onBlur={() => saveTitle(chat.id)}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") saveTitle(chat.id);
                    }}
                    autoFocus
                    fullWidth
                  />
                ) : (
                  <div
                    onClick={() => handleChatClick(chat.id)}
                    style={{
                      textDecoration: "none",
                      color: "inherit",
                      cursor: "pointer",
                      width: "100%",
                    }}
                  >
                    <ChatListItemText primary={chat.title} />
                  </div>
                )}
              </StyledListItem>
            ))}
          </StyledList>
        </SidebarContainer>
        <Outlet />
      </ContentContainer>
    </MainContainer>
  );
};

export default ChatPage;
