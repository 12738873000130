import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import { thunk } from "redux-thunk";

import filtersReducer from "./filtersReducer";
import formationsReducer from "./formationsReducer";
import fichesReducer from "./fichesReducer";
import userReducer from "./userReducer";

const persistedState = {
  user: {
    token: localStorage.getItem('authToken'),
    user: JSON.parse(localStorage.getItem('user')),
    // Initialiser les autres champs de votre état comme nécessaire
  },
};

const rootReducer = combineReducers({
  filters: filtersReducer,
  formations: formationsReducer,
  fiches: fichesReducer,
  user: userReducer,
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducer,
  persistedState,
  composeEnhancers(applyMiddleware(thunk))
);

store.subscribe(() => {
  const { user } = store.getState();
  if (user.token) {
    localStorage.setItem('authToken', user.token);
    localStorage.setItem('user', JSON.stringify(user.user));
  } else {
    localStorage.removeItem('authToken');
    localStorage.removeItem('user');
  }
});


export default store;
