import React, { useState } from "react";
import {
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Dialog,
  DialogContent,
  Typography,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Box,
  Divider,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import GradeIcon from "@mui/icons-material/Grade";

const QuizListItem = ({ data }) => {
  const [open, setOpen] = useState(false);
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [quizSubmitted, setQuizSubmitted] = useState(false);
  const [correctAnswers, setCorrectAnswers] = useState(0);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClickOpen = () => {
    setOpen(true);
    setQuizSubmitted(false);
    setSelectedAnswers({});
    setCorrectAnswers(0);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAnswerChange = (questionIndex, value) => {
    setSelectedAnswers({
      ...selectedAnswers,
      [questionIndex]: parseInt(value, 10),
    });
  };

  const handleSubmitQuiz = () => {
    let correctCount = 0;
    data.quizzes.forEach((quiz, index) => {
      if (selectedAnswers[index] === quiz.answer) {
        correctCount++;
      }
    });
    setCorrectAnswers(correctCount);
    setQuizSubmitted(true);
  };

  function formatQuestion(question) {
    return question.replace(/([^\s])(\?)/g, "$1 ?");
  }

  function getLimitedDescription(description) {
    const maxLength = isSmallScreen ? 80 : 140;
    return description.length > maxLength ? description.substring(0, maxLength) + "..." : description;
  }

  function getScoreColor(score) {
    const total = data.quizzes.length;
    const percentage = (score / total) * 100;

    if (percentage >= 90) return "green";
    if (percentage >= 70) return "limegreen";
    if (percentage >= 50) return "orange";
    if (percentage >= 30) return "orangered";
    return "red";
  }

  const renderQuiz = () => {
    if (!data.quizzes) return <Typography>Aucun quiz disponible.</Typography>;

    return data.quizzes.map((quiz, index) => (
      <Box key={index} mb={3} p={2} style={{ backgroundColor: "#f9f9f9", borderRadius: "8px" }}>
        <Typography variant="h6" gutterBottom style={{ fontWeight: "bold" }}>
          {formatQuestion(quiz.question)}
        </Typography>
        <FormControl component="fieldset">
          <RadioGroup
            value={selectedAnswers[index] ?? ""}
            onChange={(e) => handleAnswerChange(index, e.target.value)}
          >
            {quiz.options.map((option, optionIndex) => (
              <FormControlLabel
                key={optionIndex}
                value={optionIndex}
                control={<Radio />}
                label={option}
                disabled={quizSubmitted}
              />
            ))}
          </RadioGroup>
        </FormControl>
        {quizSubmitted && (
          <Typography
            variant="body2"
            color={selectedAnswers[index] === quiz.answer ? "green" : "red"}
          >
            {selectedAnswers[index] === quiz.answer
              ? "Bonne réponse !"
              : `Mauvaise réponse. La bonne réponse est : ${quiz.options[quiz.answer]}`}
          </Typography>
        )}
        <Divider style={{ marginTop: "16px" }} />
      </Box>
    ));
  };

  return (
    <>
      <ListItem button onClick={handleClickOpen} divider style={{ minHeight: "70px" }}>
        <ListItemAvatar>
          <Avatar alt="Quiz Icon" src="/quiz-icon.png" />
        </ListItemAvatar>
        <ListItemText primary={data.title} secondary={getLimitedDescription(data.description)} />
      </ListItem>
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogContent>
          <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
            <Typography variant="h4" gutterBottom>
              Quiz - {data.title}
            </Typography>
            {quizSubmitted && (
              <Box display="flex" alignItems="center" p={1} style={{
                backgroundColor: getScoreColor(correctAnswers),
                color: "white",
                borderRadius: "8px",
                padding: "8px 16px"
              }}>
                <GradeIcon style={{ marginRight: "8px" }} />
                <Typography variant="h6" style={{ fontWeight: "bold" }}>
                  {correctAnswers} / {data.quizzes.length}
                </Typography>
              </Box>
            )}
          </Box>
          {renderQuiz()}
          {!quizSubmitted && (
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmitQuiz}
              style={{ marginTop: "16px" }}
            >
              Valider le Quiz
            </Button>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default QuizListItem;
