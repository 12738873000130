import React, { useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Dialog,
  DialogContent,
  Button,
  Box,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Divider,
  useMediaQuery,
} from "@mui/material";
import PdfPreview from "./PdfPreview";
import { useTheme } from "@mui/material/styles";
import GradeIcon from "@mui/icons-material/Grade";

function formatQuestion(question) {
  return question.replace(/([^\s])(\?)/g, "$1 ?");
}

const QuizCard = ({ data }) => {
  const [open, setOpen] = useState(false);
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [quizSubmitted, setQuizSubmitted] = useState(false);
  const [correctAnswers, setCorrectAnswers] = useState(0);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClickOpen = () => {
    setOpen(true);
    setQuizSubmitted(false);
    setSelectedAnswers({});
    setCorrectAnswers(0);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAnswerChange = (questionIndex, value) => {
    setSelectedAnswers({
      ...selectedAnswers,
      [questionIndex]: parseInt(value, 10),
    });
  };

  const handleSubmitQuiz = () => {
    let correctCount = 0;
    data.quizzes.forEach((quiz, index) => {
      if (selectedAnswers[index] === quiz.answer) {
        correctCount++;
      }
    });
    setCorrectAnswers(correctCount);
    setQuizSubmitted(true);
  };

  function getLimitedDescription(description) {
    const maxLength = isSmallScreen ? 80 : 140;
    return description.length > maxLength
      ? description.substring(0, maxLength) + "..."
      : description;
  }

  function getScoreColor(score) {
    const total = data.quizzes.length;
    const percentage = (score / total) * 100;

    if (percentage >= 90) return "green";
    if (percentage >= 70) return "limegreen";
    if (percentage >= 50) return "orange";
    if (percentage >= 30) return "orangered";
    return "red";
  }

  const renderQuiz = () => {
    if (!data.quizzes || data.quizzes.length === 0) {
      return <Typography>Aucun quiz disponible.</Typography>;
    }

    return data.quizzes.map((quiz, index) => (
      <Box key={index} mb={3} p={2} style={{ backgroundColor: "#f9f9f9", borderRadius: "8px" }}>
        <Typography variant="h6" gutterBottom style={{ fontWeight: "bold" }}>
          {formatQuestion(quiz.question)}
        </Typography>
        <FormControl component="fieldset">
          <RadioGroup
            value={selectedAnswers[index] ?? ""}
            onChange={(e) => handleAnswerChange(index, e.target.value)}
          >
            {quiz.options.map((option, optionIndex) => (
              <FormControlLabel
                key={optionIndex}
                value={optionIndex}
                control={<Radio />}
                label={option}
                disabled={quizSubmitted}
              />
            ))}
          </RadioGroup>
        </FormControl>
        {quizSubmitted && (
          <Typography
            variant="body2"
            color={selectedAnswers[index] === quiz.answer ? "green" : "red"}
          >
            {selectedAnswers[index] === quiz.answer
              ? "Bonne réponse !"
              : `Mauvaise réponse. La bonne réponse est : ${quiz.options[quiz.answer]}`}
          </Typography>
        )}
        <Divider style={{ marginTop: "16px" }} />
      </Box>
    ));
  };

  return (
    <>
      <div onClick={handleClickOpen} style={{ textDecoration: "none" }}>
        <Card
          style={{
            width: "80vw",
            maxWidth: "400px",
            height: "75vh",
            maxHeight: "600px",
            display: "flex",
            flexDirection: "column",
            cursor: "pointer",
            margin: "auto",
            borderRadius: "8px",
            boxShadow: "1px 1px 6px rgba(0, 0, 0, 0.1)", // Ombre subtile
            transition: "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
          }}
          onMouseEnter={(e) => {
            e.currentTarget.style.transform = "scale(1.03)";
            e.currentTarget.style.boxShadow = "3px 3px 12px rgba(0, 0, 0, 0.2)";
          }}
          onMouseLeave={(e) => {
            e.currentTarget.style.transform = "scale(1)";
            e.currentTarget.style.boxShadow = "1px 1px 6px rgba(0, 0, 0, 0.1)";
          }}
        >
          <div
            style={{
              flex: 1,
              overflow: "hidden",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <PdfPreview pdfUrl={data.url} />
          </div>
          <CardContent style={{ flexShrink: 0, textAlign: "center", padding: "16px" }}>
            <Typography
              variant="h6"
              component="div"
              style={{ fontSize: "1.2rem", marginBottom: "8px", minHeight: "50px" }}
            >
              {data.title || "Titre indisponible"}
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              style={{ fontSize: "0.875rem", minHeight: "40px" }}
            >
              {getLimitedDescription(data.description) || "Description indisponible"}
            </Typography>
          </CardContent>
        </Card>
      </div>
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogContent>
          <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
            <Typography variant="h4" gutterBottom>
              Quiz - {data.title}
            </Typography>
            {quizSubmitted && (
              <Box
                display="flex"
                alignItems="center"
                p={1}
                style={{
                  backgroundColor: getScoreColor(correctAnswers),
                  color: "white",
                  borderRadius: "8px",
                  padding: "8px 16px",
                }}
              >
                <GradeIcon style={{ marginRight: "8px" }} />
                <Typography variant="h6" style={{ fontWeight: "bold" }}>
                  {correctAnswers} / {data.quizzes.length}
                </Typography>
              </Box>
            )}
          </Box>
          {renderQuiz()}
          {!quizSubmitted && (
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmitQuiz}
              style={{ marginTop: "16px" }}
            >
              Valider le Quiz
            </Button>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default QuizCard;
