import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Autocomplete,
  TextField,
  Grid,
  Box,
  IconButton,
  List,
  Typography,
  InputAdornment,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import PdfCard from "../component/PdfCard";
import AudioCard from "../component/AudioCard";
import VideoCard from "../component/VideoCard";
import QuizCard from "../component/QuizCard";
import FormationCard from "../component/FormationCard";
import PdfListItem from "../component/PdfListItem";
import AudioListItem from "../component/AudioListItem";
import VideoListItem from "../component/VideoListItem";
import QuizListItem from "../component/QuizListItem";
import FormationListItem from "../component/FormationListItem";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import ViewListIcon from "@mui/icons-material/ViewList";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import AudiotrackIcon from "@mui/icons-material/Audiotrack";
import MovieIcon from "@mui/icons-material/Movie";
// import QuizIcon from "@mui/icons-material/Quiz";
import QuizIcon from "@mui/icons-material/Assignment";
import SmartToyIcon from "@mui/icons-material/SmartToy";
import SchoolIcon from "@mui/icons-material/School";
import SearchIcon from "@mui/icons-material/Search";
import DescriptionIcon from "@mui/icons-material/Description";
import ChatbotComponent from "../component/ChatItem";
import staticPdfs from "./staticPdfs.json";

const StyledTypography = styled(Typography)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  fontSize: "1.2rem",
  fontWeight: "bold",
  color: theme.palette.text.primary,
}));

const IconWrapper = styled(DescriptionIcon)(({ theme }) => ({
  marginRight: theme.spacing(1),
}));

const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
  width: "100%",
  "& .MuiInputBase-root": {
    color: "inherit",
    fontFamily: "'Poppins', sans-serif",
    "& .MuiAutocomplete-input": {
      padding: "10px 6px",
      paddingLeft: theme.breakpoints.up("sm")
        ? `calc(0.15em + ${theme.spacing(4)})`
        : `calc(0.15em + ${theme.spacing(2)})`,
    },
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "transparent",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "none",
      width: "100%",
    },
    "&:hover fieldset": {
      border: "none",
    },
    "&.Mui-focused fieldset": {
      border: "none",
    },
  },
}));

const normalizeString = (str) => {
  return str
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .toLowerCase();
};

const PdfGridPage = () => {
  const [filteredPdfs, setFilteredPdfs] = useState(staticPdfs);
  const [filteredFormations, setFilteredFormations] = useState([]);
  const [viewMode, setViewMode] = useState("grid");
  const [mediaType, setMediaType] = useState("formation");
  const [searchValue, setSearchValue] = useState("");

  const formations = useSelector((state) => state.formations.formations);

  useEffect(() => {
    if (mediaType === "formation") {
      setFilteredFormations(formations);
    }
  }, [mediaType, formations]);

  const handleSearchChange = (event, value) => {
    setSearchValue(value);
    const searchTerms = { keyword: value, domaine: "", host: "" };

    if (mediaType === "formation") {
      handleFormationFilter(searchTerms);
    } else {
      handleFilter(searchTerms);
    }
  };

  const handleFilter = (searchTerms) => {
    const { keyword, domaine } = searchTerms;

    const normalizedKeyword = normalizeString(keyword || "");
    const normalizedDomaine = normalizeString(domaine || "");

    const filtered = staticPdfs.filter((item) => {
      const normalizedTitle = normalizeString(item.title || "");
      const normalizedDescription = normalizeString(item.description || "");
      const normalizedPdfDomaine = normalizeString(item.domaine || "");

      const matchesKeyword =
        !normalizedKeyword ||
        normalizedTitle.includes(normalizedKeyword) ||
        normalizedDescription.includes(normalizedKeyword);

      const matchesDomaine =
        !normalizedDomaine || normalizedPdfDomaine.includes(normalizedDomaine);

      return matchesKeyword && matchesDomaine;
    });

    setFilteredPdfs(filtered);
  };

  const handleFormationFilter = (searchTerms) => {
    const { keyword, host } = searchTerms;

    const normalizedKeyword = normalizeString(keyword || "");
    const normalizedHost = normalizeString(host || "");

    const filtered = formations.filter((item) => {
      const normalizedTitle = normalizeString(item.title || "");
      const normalizedHostName = normalizeString(item.host || "");

      const matchesKeyword =
        !normalizedKeyword || normalizedTitle.includes(normalizedKeyword);

      const matchesHost =
        !normalizedHost || normalizedHostName.includes(normalizedHost);

      return matchesKeyword && matchesHost;
    });

    setFilteredFormations(filtered);
  };

  const toggleViewMode = (mode) => {
    setViewMode(mode);
  };

  const toggleMediaType = (type) => {
    setMediaType(type);
    if (type === "formation") {
      setFilteredFormations(formations);
    }
  };

  const filteredData =
    mediaType === "formation" ? filteredFormations : filteredPdfs;

  const renderCards = (item) => {
    switch (mediaType) {
      case "audio":
        return <AudioCard audio={item} />;
      case "video":
        return <VideoCard video={item} />;
      case "quiz":
        return <QuizCard data={item} />;
      case "formation":
        return <FormationCard formation={item} />;
      case "Chatbot":
        return <FormationCard formation={item} />;
      case "pdf":
      default:
        return <PdfCard pdf={item} />;
    }
  };

  const renderLists = (item) => {
    switch (mediaType) {
      case "audio":
        return <AudioListItem audio={item} />;
      case "video":
        return <VideoListItem video={item} />;
      case "quiz":
        return <QuizListItem data={item} />;
      case "formation":
        return <FormationListItem formation={item} />;
      case "Chatbot":
        return <ChatbotComponent chatbot={item} />;
      case "pdf":
      default:
        return <PdfListItem pdf={item} />;
    }
  };

  return (
    <Box style={{ minHeight: "100vh", paddingBottom: "30px" }}>
      <StyledTypography
        style={{
          height: "50px",
          width: "fit-content",
          margin: "Auto",
          // marginTop: "20px",
          fontSize: "40px",
          color:"#1e88e5"
        }}
      >
        {" "}
        
      </StyledTypography>

      {/* Media type toggle */}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        style={{ margin: "20px 30px" }}
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          style={{ flexGrow: 1, gap: "80px" }}
        >
          <IconButton onClick={() => toggleMediaType("formation")}>
            <SchoolIcon
              sx={{ fontSize: "7rem" }}
              color={mediaType === "formation" ? "primary" : "default"}
            />
          </IconButton>
          <IconButton onClick={() => toggleMediaType("pdf")}>
            <PictureAsPdfIcon
              sx={{ fontSize: "7rem" }}
              color={mediaType === "pdf" ? "primary" : "default"}
            />
          </IconButton>
          <IconButton onClick={() => toggleMediaType("audio")}>
            <AudiotrackIcon
              sx={{ fontSize: "7rem" }}
              color={mediaType === "audio" ? "primary" : "default"}
            />
          </IconButton>
          <IconButton onClick={() => toggleMediaType("video")}>
            <MovieIcon
              sx={{ fontSize: "7rem" }}
              color={mediaType === "video" ? "primary" : "default"}
            />
          </IconButton>
          <IconButton onClick={() => toggleMediaType("quiz")}>
            <QuizIcon
              sx={{ fontSize: "7rem" }}
              color={mediaType === "quiz" ? "primary" : "default"}
            />
          </IconButton>
          <IconButton onClick={() => toggleMediaType("Chatbot")}>
            <SmartToyIcon
              sx={{ fontSize: "7rem" }}
              color={mediaType === "Chatbot" ? "primary" : "default"}
            />
          </IconButton>
        </Box>
      </Box>
      {/* Conteneur principal */}
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "20px",
          maxWidth: "100%",
          // backgroundColor: "red",
        }}
      >
        {/* Barre de recherche */}
        <Box
          style={{
            position: "absolute", // Permet de positionner la barre indépendamment des autres éléments
            left: "50%", // Place le centre de la barre au milieu de l’écran
            transform: "translateX(-50%)", // Recentre exactement sur le milieu
          }}
        >
          <StyledAutocomplete
            freeSolo
            options={[]}
            inputValue={searchValue}
            onInputChange={handleSearchChange}
            style={{ width: "300px" }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Rechercher une ressource"
                variant="outlined"
                fullWidth
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </Box>

        {/* Icônes de toggle */}
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            gap: "10px",
            flex: 1,
          }}
        >
          <IconButton onClick={() => toggleViewMode("grid")}>
            <ViewModuleIcon
              sx={{ fontSize: "2rem" }}
              color={viewMode === "grid" ? "primary" : "default"}
            />
          </IconButton>
          <IconButton onClick={() => toggleViewMode("list")}>
            <ViewListIcon
              sx={{ fontSize: "2rem" }}
              color={viewMode === "list" ? "primary" : "default"}
            />
          </IconButton>
        </Box>
      </Box>

      {/* Texte centré */}
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "10px",
        }}
      >
        <StyledTypography component="p">
          <IconWrapper />
          {filteredData.length} ressources disponibles
        </StyledTypography>
      </Box>

      {mediaType === "Chatbot" ? (
        <Box
          style={{
            width: "100%",
            maxWidth: 1280,
            margin: "auto",
            paddingRight: "30px",
          }}
        >
          {filteredData.length ? (
            // Assurez-vous qu'il n'y a qu'un seul chatbot rendu, même si filteredData contient plusieurs éléments
            <ChatbotComponent chatbot={filteredData[0]} />
          ) : (
            <Typography variant="body2">
              Aucun chatbot ne correspond à votre recherche
            </Typography>
          )}
        </Box>
      ) : viewMode === "grid" ? (
        <Grid
          container
          spacing={4}
          justifyContent="center"
          style={{
            marginTop: "20px",
            width: "100%",
            maxWidth: 1280,
            margin: "auto",
            paddingRight: "30px",
          }}
        >
          {filteredData.length ? (
            filteredData.map((item, i) => (
              <Grid item key={i} xs={12} sm={6} md={6} lg={4}>
                {renderCards(item)}
              </Grid>
            ))
          ) : (
            <Typography variant="body2">
              Aucune fiche ne correspond à votre recherche
            </Typography>
          )}
        </Grid>
      ) : (
        <List
          style={{
            width: "100%",
            maxWidth: 1280,
            margin: "auto",
            paddingRight: "30px",
          }}
        >
          {filteredData.length ? (
            filteredData.map((item, i) => renderLists(item))
          ) : (
            <Typography variant="body2">
              Aucune fiche ne correspond à votre recherche
            </Typography>
          )}
        </List>
      )}
    </Box>
  );
};

export default PdfGridPage;
