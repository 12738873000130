// fichesReducer.js
import axios from "axios";

import { API_url } from "../GlobalVariables";
// Action Types
const FETCH_fiches_BEGIN = "FETCH_fiches_BEGIN";
const FETCH_fiches_SUCCESS = "FETCH_fiches_SUCCESS";
const FETCH_fiches_FAILURE = "FETCH_fiches_FAILURE";

// Action Creators
export const fetchfichesBegin = () => ({
  type: FETCH_fiches_BEGIN,
});

export const fetchfichesSuccess = (fiches) => ({
  type: FETCH_fiches_SUCCESS,
  payload: { fiches },
});

export const fetchfichesFailure = (error) => ({
  type: FETCH_fiches_FAILURE,
  payload: { error },
});

// Reducer
const initialState = {
  fiches: [],
  loading: false,
  error: null,
};

export default function fichesReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_fiches_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_fiches_SUCCESS:
      return {
        ...state,
        loading: false,
        fiches: action.payload.fiches,
      };
    case FETCH_fiches_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        fiches: [],
      };
    default:
      return state;
  }
}

// Thunk
export function fetchfiches() {
  return async (dispatch) => {
    dispatch(fetchfichesBegin());
    try {
      const response = await axios.get(`${API_url}/fiches/fiches`);
      // console.log(response.status);
      if (response.status === 200) {
        // console.log(response.data.Formation)
        dispatch(fetchfichesSuccess(response.data.Formation));
      }
    } catch (error) {
      dispatch(fetchfichesFailure(error.toString()));
    }
  };
}
