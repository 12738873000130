import React, { useEffect, useRef, useState } from "react";
import * as pdfjsLib from "pdfjs-dist/webpack"; // Import de pdfjs-dist

// Configuration du worker de pdfjs
pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js`;

const PdfPreview = ({ pdfUrl }) => {
  const canvasRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadPdf = async () => {
      setLoading(true);
      setError(null);

      try {
        // Charger le document PDF à partir de l'URL
        const loadingTask = pdfjsLib.getDocument(pdfUrl);
        const pdf = await loadingTask.promise;

        // Récupérer la première page
        const page = await pdf.getPage(1);
        const viewport = page.getViewport({ scale: 1 });

        // Configuration du canvas
        const canvas = canvasRef.current;
        const context = canvas.getContext("2d");

        canvas.height = viewport.height;
        canvas.width = viewport.width;

        // Rendre la page sur le canvas
        const renderContext = {
          canvasContext: context,
          viewport: viewport,
        };

        await page.render(renderContext).promise;
        setLoading(false);
      } catch (err) {
        // console.error("Erreur lors du chargement du PDF:", err);
        // setError("Impossible de charger l'aperçu du PDF.");
        setLoading(false);
      }
    };

    // Charger le PDF seulement si une URL est présente
    if (pdfUrl) {
      loadPdf();
    } else {
      setError("Aucune URL de PDF fournie.");
      setLoading(false);
    }
  }, [pdfUrl]);

  return (
    <div>
      {loading && <p>Chargement de l'aperçu...</p>}
      {error && <p style={{ color: "red" }}>{error}</p>}
      <canvas ref={canvasRef} style={{ maxWidth: "100%", border: "1px solid #ccc" }} />
    </div>
  );
};

export default PdfPreview;
