import React, { useState } from "react";
import { Card, CardContent, Typography, Dialog, DialogContent, Button } from "@mui/material";
import PdfPreview from "./PdfPreview";
import PdfPreviewModal from "./PdfPreviewModal";

function getLimitedDescription(description) {
  const maxLength = 70;
  
  // Vérifie si la description dépasse la longueur maximale et la tronque si nécessaire
  if (description.length > maxLength) {
    return description.substring(0, maxLength) + "...";
  }
  
  return description;
}

const PdfCard = ({ pdf }) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div onClick={handleClickOpen} style={{ textDecoration: 'none' }}>
        <Card
          style={{
            width: '80vw', // 90% de la largeur de l'écran
            maxWidth: '400px', // Limite maximale de largeur
            height: '75vh', // 75% de la hauteur de l'écran
            maxHeight: '600px', // Limite maximale de hauteur
            display: 'flex',
            flexDirection: 'column',
            cursor: 'pointer',
            margin: 'auto', // Centrer la carte
            borderRadius: "5px",
            boxShadow: "1px 1px 6px rgba(0, 0, 0, 0.1)", // Ombre discrète
            transition: "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
          }}
          onMouseEnter={(e) => {
            e.currentTarget.style.transform = "scale(1.03)";
            e.currentTarget.style.boxShadow = "3px 3px 12px rgba(0, 0, 0, 0.2)"; // Ombre légèrement plus marquée
          }}
          onMouseLeave={(e) => {
            e.currentTarget.style.transform = "scale(1)";
            e.currentTarget.style.boxShadow = "1px 1px 6px rgba(0, 0, 0, 0.1)"; // Retour à une ombre discrète
          }}
        >
          <div
            style={{
              flex: 1,
              overflow: 'hidden',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <PdfPreview pdfUrl={pdf.url} />
          </div>
          <CardContent
            style={{
              flexShrink: 0,
              textAlign: 'center',
              padding: '16px',
            }}
          >
            <Typography
              variant="h6"
              component="div"
              style={{ fontSize: '1.2rem', marginBottom: '8px', minHeight: '50px' }}
            >
              {pdf.title}
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              style={{ fontSize: '0.875rem', minHeight: '40px' }}
            >
              {getLimitedDescription(pdf.description)}
            </Typography>
          </CardContent>
        </Card>
      </div>

      <PdfPreviewModal
        pdfUrl={pdf.url}
        open={open}
        handleClose={handleClose}
      />
    </>
  );
};

export default PdfCard;
