import React from "react";
import {
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Typography,
} from "@mui/material";

const FormationListItem = ({ formation }) => {
  const { title, description, images, host } = formation;

  return (
    <ListItem divider style={{ minHeight: "80px" }}>
      <ListItemAvatar>
        <Avatar alt="Formation Image" src={images[0]} />
      </ListItemAvatar>
      <ListItemText
        primary={
          <Typography variant="h6" style={{ fontWeight: "bold" }}>
            {title}
          </Typography>
        }
        secondary={
          <>
            <Typography variant="body2" color="textSecondary">
              {description.slice(0, 100)}...
            </Typography>
            <Typography variant="caption" color="textSecondary">
              Organisé par : {host}
            </Typography>
          </>
        }
      />
    </ListItem>
  );
};

export default FormationListItem;
